/* eslint-disable no-console */
import { STORAGE } from 'Enum';
import Keycloak from 'keycloak-js';

import { loadState, removeState, saveState } from './LocalStorage';

export const keycloak = new Keycloak({
	url: process.env.REACT_APP_KEYCLOAK_URL || '', // Your Keycloak server URL
	realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
});

export const initKeycloak = async () => {
	if (!keycloak || keycloak.didInitialize) return;
	try {
		await keycloak.init({
			onLoad: 'check-sso',
			pkceMethod: 'S256',
			token: loadState<string>(STORAGE.TOKEN) || undefined,
			refreshToken: loadState<string>(STORAGE.REFRESH_TOKEN) || undefined,
		});
		import('store').then((module) =>
			module.default.dispatch({ type: 'APP/UPDATE_AUTH', payload: keycloak.authenticated }),
		);
		if (!keycloak.authenticated) removeState([STORAGE.TOKEN, STORAGE.REFRESH_TOKEN]);
		if (keycloak.authenticated) {
			saveState(STORAGE.TOKEN, keycloak.token);
			saveState(STORAGE.REFRESH_TOKEN, keycloak.refreshToken);
		}
	} catch (error) {
		console.error('Keycloak initialize error:', error);
	}
};

export const loginKeyCloak = async (channel: string) => {
	if (!keycloak || keycloak?.authenticated) return;
	try {
		await keycloak.login({
			idpHint: channel,
		});
	} catch (error) {
		console.error('Keycloak login error:', error);
	}
};
