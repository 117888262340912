import axios, { AxiosError, AxiosRequestConfig, CancelToken, InternalAxiosRequestConfig } from 'axios';
import { STORAGE } from 'Enum';
import { IError, IErrorData } from 'types';
import { loadState } from 'utils/LocalStorage';

export const AxiosAuthInstance = axios.create({
	baseURL: 'https://api.searchspot.ai/',
	headers: {
		'Content-Type': 'application/json',
	},
});

const serializeError = (error: AxiosError<IErrorData>): IError => {
	const se = {} as IError;
	const { response } = error;
	if (!response) throw error;
	const { status, statusText, data } = response;
	se.name = 'API ERROR';
	se.message = data?.message || statusText || `API FAILED (${status})`;
	if (status === 401) {
		se.message = 'Session Expired. Please login again!';
	}
	se.code = status.toString();
	se.stack = JSON.stringify(error.toJSON());
	se.data = data;
	return se;
};

const tokenHeaderInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
	if (!config?.headers) return config;
	config.headers['lat'] = loadState(STORAGE.LAT);
	config.headers['lon'] = loadState(STORAGE.LON);
	const token = loadState(STORAGE.TOKEN);
	if (!token) return config;
	// config.headers['Authorization'] = `Bearer ${token}`;
	return config;
};

const onErrorInterceptor = (error: AxiosError<IError>): IError => {
	if (error?.response?.status === 401) {
		import('store').then((module) => module.default.dispatch({ type: 'auth/LOG_OUT' }));
	}
	throw serializeError(error);
};

AxiosAuthInstance.interceptors.request.use(tokenHeaderInterceptor);
AxiosAuthInstance.interceptors.response.use(undefined, onErrorInterceptor);

export const ApiRequest = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = CancelToken.source();
	const promise = AxiosAuthInstance({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

export const ExternalApiRequest = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = CancelToken.source();
	const instance = axios.create({
		baseURL: config.url,
		headers: {
			'Content-Type': 'application/json',
		},
	});
	const promise = instance({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

export default AxiosAuthInstance;
