import React from 'react';

import Box from './Box';
import Typography from './Typography';

interface AvatarProps {
	name: string;
	size?: number;
}

const Avatar: React.FC<AvatarProps> = ({ name, size = 40 }) => {
	const getInitials = (name: string) => {
		return name
			?.split(' ')
			?.map((word) => word.charAt(0))
			?.join('')
			?.toUpperCase()
			?.slice(0, 2);
	};

	const stringToColor = (str: string) => {
		let hash = 0;
		for (let i = 0; i < str?.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		const hue = Math.abs(hash % 360);
		return `hsl(${hue}, 70%, 70%)`;
	};

	const initials = getInitials(name);
	const backgroundColor = stringToColor(name);

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			borderRadius="100%"
			background={backgroundColor}
			width={size}
			height={size}
			aspectRatio={1}
			fontSize={size * 0.4}>
			<Typography color="white" fontSize={size * 0.4} fontWeight={500}>
				{initials}
			</Typography>
		</Box>
	);
};

export default React.memo(Avatar);
