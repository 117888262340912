import React from 'react';
import { useDispatch } from 'react-redux';

import { useMediaQuery } from 'hooks/useMediaQuery';
import { REMOVE_TOAST, useToast } from 'slices/ToastSlice';

import Box from './Box';
import Toast from './Toast';

const ToastContainer: React.FC = () => {
	const dispatch = useDispatch();
	const isDesktop = useMediaQuery();
	const toasts = useToast();

	const handleClose = (id: string) => () => {
		dispatch(REMOVE_TOAST(id));
	};

	if (toasts.length === 0) return null;

	return (
		<Box
			position="fixed"
			top={isDesktop ? 24 : 16}
			right={isDesktop ? 24 : 0}
			zIndex={100000000}
			display="flex"
			flexDirection="column"
			alignItems="flex-end"
			gap={8}
			width={isDesktop ? 'auto' : '100%'}>
			{toasts.map((toast) => React.Children.toArray(<Toast {...toast} onClose={handleClose(toast.id)} />))}
		</Box>
	);
};

export default React.memo(ToastContainer);
