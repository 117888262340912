import { useEffect, useState } from 'react';

import { DESKTOP_SCREEN } from 'theme';

export const useMediaQuery = (query = DESKTOP_SCREEN): boolean => {
	const [isMatch, setMatch] = useState<boolean>(false);

	useEffect(() => {
		setMatch(window.matchMedia && window.matchMedia(query).matches);

		const updateQueryScheme = () => setMatch(window.matchMedia && window.matchMedia(query).matches);

		window.matchMedia(query).addEventListener('change', updateQueryScheme);

		return () => {
			window.matchMedia(query).removeEventListener('change', updateQueryScheme);
		};
	}, [query]);

	return isMatch;
};
