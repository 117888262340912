import { useSelector } from 'react-redux';

import * as amplitude from '@amplitude/analytics-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { v4 as uuidv4 } from 'uuid';

export interface IToast {
	id: string;
	message: string;
	description?: string;
	type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'CONGRATS';
	duration?: number;
}

interface ToastState {
	toasts: IToast[];
}

const initialState: ToastState = {
	toasts: [],
};

const ToastSlice = createSlice({
	name: 'TOAST',
	initialState,
	reducers: {
		SHOW_TOAST: (state, action: PayloadAction<Omit<IToast, 'id'>>) => {
			amplitude.track('TOAST', {
				type: action.payload.type,
				message: action.payload.message,
				description: action.payload.description,
			});
			const toast = {
				...action.payload,
				id: uuidv4(),
			};
			state.toasts.push(toast);
		},
		REMOVE_TOAST: (state, action: PayloadAction<string>) => {
			state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
		},
	},
});

export const { SHOW_TOAST, REMOVE_TOAST } = ToastSlice.actions;
export default ToastSlice;

export interface IToastStates {
	[ToastSlice.name]: ReturnType<typeof ToastSlice.reducer>;
}

const toastSelector = (state: IToastStates): Array<IToast> => state[ToastSlice.name].toasts;
export const useToast = (): Array<IToast> => useSelector(toastSelector);
