import React from 'react';

import { IIconProps } from 'types';

const HamBurgerIcon: React.FC<IIconProps> = ({ htmlColor, onClick, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			onClick={onClick}
			{...props}>
			<path
				d="M3.67065 10H17.004M3.67065 5H17.004M3.67065 15H17.004"
				stroke={htmlColor}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default React.memo(HamBurgerIcon);
