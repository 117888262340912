import 'styles/animate.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/variable.css';

import React, { useEffect } from 'react';

import ToastContainer from 'components/ToastContainer';
import { STORAGE } from 'Enum';
import Layout from 'layout';
import ToastSlice from 'slices/ToastSlice';
import { registerSlice } from 'store';
import { initKeycloak } from 'utils/KeyCloak';
import { loadState } from 'utils/LocalStorage';

registerSlice(ToastSlice);

const App: React.FC = () => {
	const token = loadState<string>(STORAGE.TOKEN);

	useEffect(() => {
		if (!token) return;
		initKeycloak();
	}, [token]);

	return (
		<>
			<Layout />
			<ToastContainer />
		</>
	);
};

export default App;
