/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ChatSessionInfo } from 'api/models/chatSessionInfo';
import AddIcon from 'assets/AddIcon';
import ArrowLeftToLine from 'assets/ArrowLeftToLine';
import ChevronRight from 'assets/ChevronRight';
import CrossIcon from 'assets/CrossIcon';
import HamburgerIcon from 'assets/HamburgerIcon';
import SearchSpotIcon from 'assets/SearchSpotIcon';
import { CURRENCIES } from 'CONSTANTS';
import { PATH } from 'Enum';
import { useMediaQuery } from 'hooks/useMediaQuery';
import {
	CLEAR_SESSION,
	loadSessionDetails,
	loadSessionHistory,
	useActiveCurrency,
	useAuth,
	useSessionHistory,
} from 'slices';
import theme, { DESKTOP_SCREEN } from 'theme';
import { TDispatch } from 'types';
import { keycloak } from 'utils/KeyCloak';

import Avatar from './Avatar';
import Box from './Box';
import Button from './Button';
import CurrencySelectionModal from './CurrencySelectionModal';
import Divider from './Divider';
import Typography from './Typography';

interface IProps {
	show?: boolean;
}

interface IVisibleStates {
	currencyModal: boolean;
}

type SidebarState = 'expand' | 'collapse' | 'hidden' | 'mobile';

const CATEGORIES = [
	{ title: 'Today', startDate: new Date().setHours(0, 0, 0, 0) },
	{
		title: 'Last 7 Days',
		startDate: new Date(new Date().setDate(-7)).setHours(0, 0, 0, 0),
		endDate: new Date().setHours(0, 0, 0, 0),
	},
	{ title: 'Earlier', endDate: new Date(new Date().setDate(-7)).setHours(0, 0, 0, 0) },
];

const useStyles = (sidebarState: SidebarState): Record<string, React.CSSProperties> => ({
	newChatButton: {
		border: '1px solid #3E5A8B',
		boxShadow: '0px 2px 1px 0px rgba(255, 255, 255, 0.45) inset',
		...(sidebarState === 'collapse' && {
			width: 34,
			height: 34,
			padding: 0,
		}),
		...(sidebarState === 'hidden' && {
			width: 0,
			height: 0,
			padding: 0,
		}),
	},
	sidebarContainer: {
		width: theme.sidebar[sidebarState] || 0,
		position: 'relative',
		padding: '58px 16px',
		top: 0,
		background: '#F5F7FA',
		left: 0,
		zIndex: 1000,
		...(sidebarState === 'hidden' && {
			padding: 0,
		}),
		...(sidebarState === 'mobile' && {
			position: 'fixed',
			background: '#fff',
			height: 'calc(100dvh - 116px)',
		}),
	},
});

const SideBar: React.FC<IProps> = ({ show }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch<TDispatch>();
	const authenticated = useAuth();
	const sessions = useSessionHistory();
	const activeCurrency = useActiveCurrency();
	const isMobile = !useMediaQuery(DESKTOP_SCREEN);
	const location = useLocation();

	const [sidebarState, setSidebarState] = useState<SidebarState>(isMobile ? 'hidden' : 'expand');

	const STYLES = useStyles(sidebarState);
	const [sessionHistoryHeight, setSessionHistoryHeight] = useState<string | number>(0);
	const [visible, setVisible] = useState<IVisibleStates>({
		currencyModal: false,
	});
	const [sessionHistories, setSessionHistories] = useState<Record<string, ChatSessionInfo[]>>({});

	const startNewChat = () => {
		dispatch(CLEAR_SESSION());
		routeToHome();
	};

	const toggleSidebar = () => {
		if (isMobile) {
			setSidebarState((prev) => (prev === 'hidden' ? 'mobile' : 'hidden'));
			return;
		}
		setSidebarState((prev) => (prev === 'expand' ? 'collapse' : 'expand'));
	};

	const routeToSignIn = () => {
		if (isMobile) setSidebarState('hidden');
		navigate(PATH.SIGN_IN);
	};

	const routeToHome = () => {
		if (isMobile) setSidebarState('hidden');
		navigate(PATH.HOME);
	};

	const showModal = (key: keyof IVisibleStates) => () => setVisible((prev) => ({ ...prev, [key]: true }));
	const closeModal = (key: keyof IVisibleStates) => () => setVisible((prev) => ({ ...prev, [key]: false }));

	const handleSession = (sessionId: string) => () => {
		if (isMobile) toggleSidebar();
		dispatch(loadSessionDetails(navigate, sessionId));
	};

	const isExpanded = () => sidebarState === 'expand' || sidebarState === 'mobile';

	useEffect(() => {
		if (!sessions?.length) return;
		const sessionsGroup: Record<string, ChatSessionInfo[]> = {};
		sessions.forEach((session) => {
			if (!session.modifiedAt) return;
			CATEGORIES.forEach((category) => {
				if (!session.modifiedAt) return;
				if (category.startDate && category.startDate > session.modifiedAt) return;
				if (category.endDate && category.endDate <= session.modifiedAt) return;
				if (!sessionsGroup[category.title]) sessionsGroup[category.title] = [session];
				sessionsGroup[category.title].push(session);
			});
		});
		setSessionHistories(sessionsGroup);
	}, [sessions]);

	useEffect(() => {
		dispatch(loadSessionHistory());
	}, [dispatch]);

	useEffect(() => {
		setSidebarState(!show || isMobile ? 'hidden' : 'expand');
	}, [isMobile, show]);

	useLayoutEffect(() => {
		if (!isExpanded()) return;
		const divider = document.getElementById('sidebar-top-divider');
		if (!divider) return;
		const bottom = document.getElementById('sidebar-bottom');
		if (!bottom) return;
		setSessionHistoryHeight(bottom.getBoundingClientRect().top - divider.getBoundingClientRect().bottom - 40);
	}, [sidebarState]);

	if (!show) return null;
	if (sidebarState === 'hidden' && isMobile) {
		if (location.pathname !== PATH.HOME) {
			return (
				<Box position="fixed" zIndex={1000} right={16} top="6dvh">
					<HamburgerIcon htmlColor="#334971" className="pointer" onClick={toggleSidebar} />
				</Box>
			);
		}
		return (
			<Box position="fixed" zIndex={1000} width="100%">
				<Box padding="6dvh 16px 0" display="flex" alignItems="center" justifyContent="space-between">
					<Box display="flex" alignItems="center" gap={4} onClick={routeToHome}>
						<SearchSpotIcon width="24px" height="24px" />
						<Typography
							color="#4B4C55"
							fontSize={18}
							fontWeight={500}
							fontFamily="medium-font"
							className="animated fadeIn nowrap">
							SearchSpot AI
						</Typography>
					</Box>
					<HamburgerIcon htmlColor="#334971" className="pointer" onClick={toggleSidebar} />
				</Box>
			</Box>
		);
	}

	return (
		<Box
			id="sidebar_home"
			className="animated fadeInLeft"
			display="flex"
			alignItems="center"
			flexDirection="column"
			overflowY="auto"
			gap={20}
			transition="width 0.3s ease-in-out"
			justifyContent="space-between"
			sx={STYLES.sidebarContainer}>
			<Box display="flex" flexDirection="column" gap={20} width="100%">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
					marginBottom={isExpanded() ? 27 : 8}>
					<Box display="flex" alignItems="center" gap={8} onClick={routeToHome}>
						<SearchSpotIcon width="30px" height="30px" />
						{isExpanded() && (
							<Typography
								color="#4B4C55"
								fontSize={20}
								fontWeight={500}
								fontFamily="medium-font"
								className="animated fadeIn nowrap">
								SearchSpot AI
							</Typography>
						)}
					</Box>
					{isExpanded() && (
						<Box onClick={toggleSidebar} className="pointer" display="flex">
							{sidebarState === 'mobile' ? (
								<CrossIcon htmlColor="#000" />
							) : (
								<ArrowLeftToLine htmlColor="#000" />
							)}
						</Box>
					)}
				</Box>
				<Button
					variant="contained"
					startIcon={<AddIcon htmlColor="#fff" />}
					sx={STYLES.newChatButton}
					onClick={startNewChat}>
					{isExpanded() && <span className="animated fadeIn">New Chat</span>}
				</Button>
				<Divider variant="dashed" color="#E9ECF5" id="sidebar-top-divider" />
				{sidebarState === 'collapse' && (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						borderRadius="100%"
						border="1px solid #CED8E9"
						height={32}
						width={32}
						background="#E9ECF5"
						onClick={toggleSidebar}
						transform="rotate(180deg)"
						transition="transform 0.3s ease-in-out"
						className="animated pointer">
						<ArrowLeftToLine htmlColor="#000" />
					</Box>
				)}
				{isExpanded() && (
					<Box
						display="flex"
						flexDirection="column"
						gap={20}
						width="100%"
						height={sessionHistoryHeight}
						overflowY="auto">
						{Object.entries(sessionHistories).map(([key, value]) =>
							React.Children.toArray(
								<Box display="flex" flexDirection="column" gap={12}>
									<Typography color="#5771A0">{key}</Typography>
									<Box display="flex" flexDirection="column" gap={6} width="100%">
										{value?.map((session, index) => {
											const { id = '', name } = session;
											return React.Children.toArray(
												<Button
													variant="normal"
													onClick={handleSession(id)}
													className="animated fadeInLeft"
													sx={{
														animationDelay: `${(index + 1) * 100}ms`,
														background: '#FAFBFD',
													}}>
													<Typography
														fontSize={15}
														color="#4B4C55"
														className="ellipsis"
														sx={{ width: '100%', display: 'block' }}>
														{name}
													</Typography>
												</Button>,
											);
										})}
									</Box>
								</Box>,
							),
						)}
					</Box>
				)}
			</Box>

			<Box display="flex" flexDirection="column" gap={20} width="100%" id="sidebar-bottom">
				<Divider variant="dashed" color="#E9ECF5" />
				<Box
					display="flex"
					alignItems="center"
					justifyContent={!isExpanded() ? 'center' : 'space-between'}
					padding={10}
					cursor="pointer"
					onClick={showModal('currencyModal')}
					borderRadius={8}
					background="#FAFBFD">
					<Box display="flex" alignItems="center" gap={10}>
						<Typography>{CURRENCIES[activeCurrency].symbol}</Typography>
						{isExpanded() && <Typography>{CURRENCIES[activeCurrency].name}</Typography>}
					</Box>
					{isExpanded() && <ChevronRight htmlColor="#334971" />}
				</Box>
				{isExpanded() && !authenticated && (
					<Button
						variant="contained"
						width="-webkit-fill-available"
						sx={STYLES.newChatButton}
						onClick={routeToSignIn}>
						Sign In
					</Button>
				)}
				{authenticated && (
					<Box display="flex" alignItems="center" gap={6} width="100%" className="animated fadeInRight">
						<Avatar name={keycloak.tokenParsed?.['name']} />
						{isExpanded() && (
							<Typography
								fontSize={15}
								color="#38496C"
								sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 'inherit' }}>
								{keycloak.tokenParsed?.['name'] || keycloak.tokenParsed?.['email']}
							</Typography>
						)}
					</Box>
				)}
			</Box>
			{visible.currencyModal && <CurrencySelectionModal onClose={closeModal('currencyModal')} />}
		</Box>
	);
};

export default React.memo(SideBar);
