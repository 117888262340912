import React, { useState } from 'react';

export interface IBoxProps extends React.CSSProperties {
	className?: string;
	children?: React.ReactNode;
	sx?: React.CSSProperties;
	hover?: React.CSSProperties;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	id?: string;
	ref?: React.RefObject<HTMLDivElement>;
	onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
	onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
	onTouchStart?: (event: React.TouchEvent<HTMLElement>) => void;
	onTouchMove?: (event: React.TouchEvent<HTMLElement>) => void;
	onTouchEnd?: (event: React.TouchEvent<HTMLElement>) => void;
	onTransitionEnd?: (event: React.TransitionEvent<HTMLElement>) => void;
	onWheel?: (event: React.WheelEvent<HTMLElement>) => void;
	onScroll?: (event: React.UIEvent<HTMLElement>) => void;
}

// eslint-disable-next-line react/display-name
const Box = React.forwardRef<HTMLDivElement, IBoxProps>(
	(
		{
			children,
			sx,
			hover,
			onClick,
			id,
			className,
			onMouseEnter,
			onMouseLeave,
			onTouchStart,
			onTouchMove,
			onTransitionEnd,
			onWheel,
			onTouchEnd,
			onScroll,
			...props
		},
		ref,
	) => {
		const [isHovered, setIsHovered] = useState(false);

		const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
			if (onMouseEnter) onMouseEnter(event);
			setIsHovered(true);
		};

		const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
			if (onMouseLeave) onMouseLeave(event);
			setIsHovered(false);
		};

		const mergedStyles = {
			...sx,
			...(isHovered ? hover : {}),
		};

		return (
			<div
				className={className}
				id={id}
				ref={ref}
				onScroll={onScroll}
				onClick={onClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTransitionEnd={onTransitionEnd}
				onWheel={onWheel}
				onTouchEnd={onTouchEnd}
				style={{ ...props, ...mergedStyles }}>
				{children}
			</div>
		);
	},
);

export default React.memo(Box);
