import { IToast } from 'slices/ToastSlice';

export default {
	show: (toast: Omit<IToast, 'id'>) => {
		import('store').then((module) => module.default.dispatch({ type: 'TOAST/SHOW_TOAST', payload: toast }));
	},
	success: (message: string, description?: string, duration?: number) => {
		import('store').then((module) =>
			module.default.dispatch({
				type: 'TOAST/SHOW_TOAST',
				payload: { type: 'SUCCESS', message, description, duration },
			}),
		);
	},
	error: (message: string, description?: string, duration?: number) => {
		import('store').then((module) =>
			module.default.dispatch({
				type: 'TOAST/SHOW_TOAST',
				payload: { type: 'ERROR', message, description, duration },
			}),
		);
	},
	warning: (message: string, description?: string, duration?: number) => {
		import('store').then((module) =>
			module.default.dispatch({
				type: 'TOAST/SHOW_TOAST',
				payload: { type: 'WARNING', message, description, duration },
			}),
		);
	},
	congrats: (message: string, description?: string, duration?: number) => {
		import('store').then((module) =>
			module.default.dispatch({
				type: 'TOAST/SHOW_TOAST',
				payload: { type: 'CONGRATS', message, description, duration },
			}),
		);
	},
};
