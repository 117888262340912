/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';

type Parameters<T> = T extends (...args: infer P) => any ? P : never;
type ReturnType<T> = T extends (...args: any) => Promise<infer R> ? R : never;

export const createAppThunk = <Func extends (...args: any[]) => Promise<any>>(typePrefix: string, func: Func) => {
	return createAsyncThunk<ReturnType<Func>, Parameters<typeof func>>(
		typePrefix,
		async (params) => await func(...params),
	);
};
