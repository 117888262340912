import React, { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'Enum';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { ROUTE_CONFIG } from './config';
import Screen from './Screen';

const Layout: React.FC = () => {
	const desktopView = useMediaQuery();

	const screens = ROUTE_CONFIG(window.location.pathname, desktopView);

	return (
		<Suspense fallback={<></>}>
			<Router>
				<Screen>
					<Routes>
						{screens.map((i) => React.Children.toArray(<Route path={i.path} element={i.element} />))}
						<Route path="*" element={<Navigate to={PATH.WELCOME} />} />
					</Routes>
				</Screen>
			</Router>
		</Suspense>
	);
};

export default React.memo(Layout);
