import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SearchIcon from 'assets/SearchIcon';
import { CURRENCIES } from 'CONSTANTS';
import { SET_ACTIVE_CURRENCY } from 'slices';
import theme from 'theme';
import { ICurrency, TDispatch } from 'types';

import Box from './Box';
import Button from './Button';
import Divider from './Divider';
import Modal from './Modal';
import { TextField } from './TextField';
import Typography from './Typography';

interface IProps {
	onClose: () => void;
}

interface IListItem extends ICurrency {
	code: string;
}

const CurrencySelectionModal: React.FC<IProps> = ({ onClose }) => {
	const dispatch = useDispatch<TDispatch>();
	const [searchValue, setSearchValue] = useState<string>('');
	const [list, setList] = useState<Array<IListItem>>([]);

	const handleCurrencyChange = (currency: string) => () => {
		dispatch(SET_ACTIVE_CURRENCY(currency));
		onClose();
	};

	const getDefaultList = () => {
		const list: Array<IListItem> = [];
		Object.entries(CURRENCIES).forEach(([key, value]) => {
			list.push({
				...value,
				code: key,
			});
		});
		return list;
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

	useEffect(() => {
		const defaultList = getDefaultList();
		if (!searchValue) return setList(defaultList);
		const items = defaultList?.filter(function (item) {
			if (!searchValue) return item;
			return (
				item?.name.toLowerCase().search(searchValue.toLowerCase()) !== -1 ||
				item?.code.toLowerCase().search(searchValue.toLowerCase()) !== -1 ||
				item?.country.toLowerCase().search(searchValue.toLowerCase()) !== -1
			);
		});
		setList(items);
	}, [searchValue]);

	return (
		<Modal closeIcon onClose={onClose} sx={{ padding: '20px' }}>
			<Box display="flex" flexDirection="column" gap={22}>
				<Box display="flex" flexDirection="column" gap={16}>
					<Typography fontSize={20} fontWeight={600}>
						Select currency
					</Typography>
					<Divider />
					<TextField
						startAdornment={<SearchIcon htmlColor="#A3B6D6" />}
						placeholder="Search by country or currency"
						value={searchValue}
						onChange={handleValueChange}
					/>
					<Box height={400} overflowY="auto" display="flex" flexDirection="column" gap={12}>
						{list.map((item) => {
							const { name, country, flag, code } = item;
							return React.Children.toArray(
								<Button variant="normal" onClick={handleCurrencyChange(code)}>
									<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
										<Box display="flex" alignItems="center" gap={12}>
											<Typography fontSize={20}>{flag}</Typography>
											<Box display="flex" gap={4} flexDirection="column">
												<Typography fontSize={16} fontWeight={600}>
													{name}
												</Typography>
												<Typography fontSize={12} fontWeight={600}>
													{country}
												</Typography>
											</Box>
										</Box>
										<Box
											background="linear-gradient(93.13deg, rgba(222, 127, 84, 0.08) -0.84%, rgba(223, 99, 200, 0.08) 99.29%)"
											padding="2px 4px"
											display="flex"
											borderRadius={4}>
											<Typography fontSize={12} fontWeight={600} sx={theme.primaryText}>
												{code}
											</Typography>
										</Box>
									</Box>
								</Button>,
							);
						})}
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default React.memo(CurrencySelectionModal);
