import React, { KeyboardEvent } from 'react';

import { useMediaQuery } from 'hooks/useMediaQuery';
import { DESKTOP_SCREEN } from 'theme';

type TVariant = 'outlined' | 'normal';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
	sx?: React.CSSProperties;
	sxProps?: {
		startAdornment?: React.CSSProperties;
		endAdornment?: React.CSSProperties;
		input?: React.CSSProperties;
	};
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	multiline?: number;
	ref?: React.RefObject<HTMLInputElement>;
	textAreaRef?: React.RefObject<HTMLTextAreaElement>;
	onEnter?: () => void;
	variant?: TVariant;
}

const variantStyles: Record<TVariant, Record<string, React.CSSProperties>> = {
	outlined: {
		main: {
			border: `1px solid #CED8E9`,
			borderRadius: 12,
		},
		input: {
			padding: '8px 16px',
		},
	},
	normal: {
		main: {
			border: 'none',
		},
	},
};

const useStyle = (desktopView: boolean): Record<string, React.CSSProperties> => ({
	main: {
		borderRadius: '14px',
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		background: '#fff',
	},
	input: {
		padding: '8px 12px 8px 6px',
		border: 'none',
		outline: 'none',
		borderRadius: 'inherit',
		width: '100%',
		fontSize: 18,
		fontFamily: 'regular-font',
		...(!desktopView && {
			fontSize: 15,
		}),
	},
	startAdornment: {
		display: 'flex',
		padding: '8px 0 8px 8px',
	},
	endAdornment: {
		display: 'flex',
		padding: '8px 8px 8px 0',
	},
});

export const TextField: React.FC<IProps> = ({
	sx,
	sxProps,
	startAdornment,
	endAdornment,
	multiline = 0,
	ref,
	textAreaRef,
	onEnter,
	variant = 'outlined',
	...props
}) => {
	const desktopView = useMediaQuery(DESKTOP_SCREEN);
	const style = useStyle(desktopView);
	const variantStyle = variantStyles[variant];

	const handleKeyDownEnterWrap = (fn: () => void) => {
		const keyDown = (e: KeyboardEvent) => {
			e.keyCode === 13 && fn();
		};
		return keyDown;
	};

	const defaultEnter = () => {
		return;
	};

	if (multiline)
		return (
			<div style={{ ...style.main, ...variantStyle?.main, ...sx }}>
				<textarea
					ref={textAreaRef}
					style={{ ...style.input, ...variantStyle?.input, ...sxProps?.input }}
					rows={multiline}
					onKeyDown={handleKeyDownEnterWrap(onEnter || defaultEnter)}
					{...props}
				/>
			</div>
		);

	return (
		<div style={{ ...style.main, ...variantStyle?.main, ...sx }}>
			{!!startAdornment && (
				<div style={{ ...style.startAdornment, ...sxProps?.startAdornment }}>{startAdornment}</div>
			)}
			<input
				ref={ref}
				style={{ ...style.input, ...variantStyle?.input, ...sxProps?.input }}
				onKeyDown={handleKeyDownEnterWrap(onEnter || defaultEnter)}
				{...props}
			/>
			{!!endAdornment && <div style={{ ...style.endAdornment, ...sxProps?.endAdornment }}>{endAdornment}</div>}
		</div>
	);
};
