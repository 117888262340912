import React from 'react';

import { IIconProps } from 'types';

const SearchIcon: React.FC<IIconProps> = ({ htmlColor, ...props }) => {
	return (
		<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.25 12.75L9.74167 10.2417M11.0833 6.91667C11.0833 9.494 8.994 11.5833 6.41667 11.5833C3.83934 11.5833 1.75 9.494 1.75 6.91667C1.75 4.33934 3.83934 2.25 6.41667 2.25C8.994 2.25 11.0833 4.33934 11.0833 6.91667Z"
				stroke={htmlColor}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default React.memo(SearchIcon);
