import React from 'react';

import CrossIcon from 'assets/CrossIcon';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { DESKTOP_SCREEN } from '../theme';
import Box from './Box';

type TModalType = 'center' | 'left' | 'right';

interface IProps {
	type?: 'center' | 'left' | 'right';
	sx?: React.CSSProperties;
	children?: React.ReactNode;
	className?: string;
	onClose?: () => void;
	closeIcon?: boolean;
}

const useStyles = (desktopView: boolean): Record<TModalType, React.CSSProperties> => ({
	center: {
		position: 'fixed',
		minWidth: '30dvw',
		borderRadius: 16,
		...(!desktopView && {
			position: 'fixed',
			width: '-webkit-fill-available',
			transform: 'none',
			top: 'unset',
			left: 4,
			bottom: 4,
			right: 4,
		}),
	},
	left: {
		position: 'fixed',
		left: 0,
		height: '100dvh',
		minWidth: '30dvw',
	},
	right: {
		position: 'fixed',
		right: 0,
		height: '100dvh',
		minWidth: '30dvw',
	},
});

const Modal: React.FC<IProps> = ({ type = 'center', sx, children, className, onClose, closeIcon = false }) => {
	const desktopView = useMediaQuery(DESKTOP_SCREEN);
	const style = useStyles(desktopView);

	const handleChange = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.stopPropagation();
	};

	const getAnimatedClass = () => {
		if (type === 'center') return desktopView ? 'zoomIn' : 'fadeInUp';
		if (type === 'left') return 'fadeInLeft';
		return 'fadeInRight';
	};

	return (
		<Box
			position="fixed"
			top={0}
			left={0}
			width="100dvw"
			height="100dvh"
			zIndex={10000000}
			onClick={onClose}
			backdropFilter="blur(20px)"
			{...(type === 'center' &&
				desktopView && {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				})}>
			<Box
				zIndex={1001}
				onClick={handleChange}
				className={`${className} animated ${getAnimatedClass()}`}
				background="#fff"
				sx={{ ...style?.[type], ...sx }}>
				{closeIcon && (
					<Box position="absolute" top={16} right={16}>
						<CrossIcon htmlColor="#7E8B99" className="pointer" onClick={onClose} />
					</Box>
				)}
				{children}
			</Box>
		</Box>
	);
};

export default React.memo(Modal);
