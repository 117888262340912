import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getSessionDetails, getSessionHistory, startNewChat, updateSession } from 'api/chat-rest-api/chat-rest-api';
import { currencyExchangeRate } from 'api/external-api/currencyApi';
import { ChatSessionDetails } from 'api/models/chatSessionDetails';
import { ChatSessionInfo } from 'api/models/chatSessionInfo';
import { ExchangeRates } from 'api/models/exchangeCurrency';
import { IFilteredSpot } from 'api/models/filteredSpot';
import Toasty from 'components/Toasty';
import { SOMETHING_WENT_WRONG } from 'CONSTANTS';
import { PATH, PATH_VARIABLE, STORAGE } from 'Enum';
import { IAction, TDispatch } from 'types';
import { loadState, saveState } from 'utils/LocalStorage';
import { createAppThunk } from 'utils/ThunkUtils';

export const NewChat = createAppThunk('new/chat', startNewChat);
export const GetSession = createAppThunk('get/session', getSessionDetails);
export const UpdateSession = createAppThunk('update/session', updateSession);
export const CurrencyExchangeRate = createAppThunk('currency/exchange/rate', currencyExchangeRate);
export const SessionHistory = createAppThunk('get/session/history', getSessionHistory);

interface ILoadingStates {
	sessionHistory: boolean;
	sessionLoading: boolean;
}

export interface IAppState {
	isAuthenticated: boolean;
	chatSessionDetails: ChatSessionDetails;
	selectedSpot: IFilteredSpot;
	detailedSelectedSot: IFilteredSpot;
	activeCurrency: string;
	exchangeRate: Record<string, number>;
	sessionHistory: ChatSessionInfo[];
	loading: ILoadingStates;
}

const INITIAL_STATE: IAppState = {
	isAuthenticated: false,
	chatSessionDetails: {} as ChatSessionDetails,
	selectedSpot: {} as IFilteredSpot,
	detailedSelectedSot: {} as IFilteredSpot,
	activeCurrency: loadState(STORAGE.CURRENCY) || 'INR',
	exchangeRate: {},
	sessionHistory: [],
	loading: {
		sessionHistory: false,
		sessionLoading: false,
	},
};

const updateLoadingState = (state: IAppState, key: keyof ILoadingStates, value: boolean) => {
	state.loading[key] = value;
};

const AppSlice = createSlice({
	name: 'APP',
	initialState: INITIAL_STATE,
	reducers: {
		UPDATE_AUTH: (state, action: IAction<boolean>) => {
			state.isAuthenticated = action.payload;
		},
		CLEAR_SESSION: (state) => {
			state.chatSessionDetails = INITIAL_STATE.chatSessionDetails;
		},
		SET_SPOT: (state, action: IAction<IFilteredSpot>) => {
			state.selectedSpot = action?.payload;
		},
		SET_DETAILED_SPOT: (state, action: IAction<IFilteredSpot>) => {
			state.detailedSelectedSot = action?.payload;
		},
		ADD_CHAT_MESSAGE: (state, action: IAction<string>) => {
			const sessionDetails = { ...state.chatSessionDetails };
			const newChatContext = { ...sessionDetails.chatContext };
			const newAudits = newChatContext?.audits || [];
			newAudits.push({
				auditType: 'USER_PROMPT',
				attributes: { input: { prompt: action.payload } },
			});
			sessionDetails['chatContext'] = { ...newChatContext, audits: newAudits };
			state.chatSessionDetails = sessionDetails;
		},
		SET_ACTIVE_CURRENCY: (state, action: IAction<string>) => {
			saveState(STORAGE.CURRENCY, action?.payload);
			state.activeCurrency = action?.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(NewChat.pending, (state) => {
			updateLoadingState(state, 'sessionLoading', true);
		});
		builder.addCase(NewChat.fulfilled, (state, action: PayloadAction<ChatSessionDetails>) => {
			updateLoadingState(state, 'sessionLoading', false);
			state.chatSessionDetails = action.payload;
			if (action.payload.sessionInfo) {
				state.sessionHistory = [action.payload.sessionInfo, ...state.sessionHistory];
			}
		});
		builder.addCase(NewChat.rejected, (state, action) => {
			updateLoadingState(state, 'sessionLoading', false);
			Toasty.error(action.error.message || SOMETHING_WENT_WRONG);
		});
		builder.addCase(GetSession.pending, (state) => {
			updateLoadingState(state, 'sessionLoading', true);
		});
		builder.addCase(GetSession.fulfilled, (state, action: PayloadAction<ChatSessionDetails>) => {
			updateLoadingState(state, 'sessionLoading', false);
			state.chatSessionDetails = action.payload;
		});
		builder.addCase(GetSession.rejected, (state, action) => {
			updateLoadingState(state, 'sessionLoading', false);
			Toasty.error(action.error.message || SOMETHING_WENT_WRONG);
		});
		builder.addCase(UpdateSession.pending, (state) => {
			updateLoadingState(state, 'sessionLoading', true);
		});
		builder.addCase(UpdateSession.fulfilled, (state, action: PayloadAction<ChatSessionDetails>) => {
			updateLoadingState(state, 'sessionLoading', false);
			state.chatSessionDetails = action.payload;
		});
		builder.addCase(UpdateSession.rejected, (state, action) => {
			updateLoadingState(state, 'sessionLoading', false);
			Toasty.error(action.error.message || SOMETHING_WENT_WRONG);
		});
		builder.addCase(CurrencyExchangeRate.fulfilled, (state, action: PayloadAction<ExchangeRates>) => {
			updateLoadingState(state, 'sessionLoading', false);
			state.exchangeRate = action.payload.rates;
		});
		builder.addCase(SessionHistory.pending, (state) => {
			updateLoadingState(state, 'sessionHistory', true);
		});
		builder.addCase(SessionHistory.rejected, (state, action) => {
			updateLoadingState(state, 'sessionHistory', false);
			Toasty.error(action.error.message || SOMETHING_WENT_WRONG);
		});
		builder.addCase(SessionHistory.fulfilled, (state, action: PayloadAction<ChatSessionInfo[]>) => {
			updateLoadingState(state, 'sessionHistory', false);
			state.sessionHistory = action.payload;
		});
	},
});

export const { UPDATE_AUTH, CLEAR_SESSION, SET_SPOT, ADD_CHAT_MESSAGE, SET_DETAILED_SPOT, SET_ACTIVE_CURRENCY } =
	AppSlice.actions;

export default AppSlice;

export interface IAppStates {
	[AppSlice.name]: ReturnType<typeof AppSlice.reducer>;
}

const authSelector = (state: IAppStates): boolean => state[AppSlice.name].isAuthenticated;
export const useAuth = (): boolean => useSelector(authSelector);

const chatSessionDetailsSelector = (state: IAppStates): ChatSessionDetails => state[AppSlice.name].chatSessionDetails;
export const useChatSessionDetails = (): ChatSessionDetails => useSelector(chatSessionDetailsSelector);

export const useSessionId = (): string => useSelector(chatSessionDetailsSelector).sessionInfo?.id || '';

const selectedSpotSelector = (state: IAppStates): IFilteredSpot => state[AppSlice.name].selectedSpot;
export const useSpot = (): IFilteredSpot => useSelector(selectedSpotSelector);

const selectedDetailedSpotSelector = (state: IAppStates): IFilteredSpot => state[AppSlice.name].detailedSelectedSot;
export const useDetailedSpot = (): IFilteredSpot => useSelector(selectedDetailedSpotSelector);

const sessionLoadingSelector = (state: IAppStates): ILoadingStates => state[AppSlice.name].loading;
export const useSessionLoading = (): boolean => useSelector(sessionLoadingSelector)?.sessionLoading;
export const useSessionHistoryLoading = (): boolean => useSelector(sessionLoadingSelector)?.sessionHistory;

const sessionHistorySelector = (state: IAppStates): ChatSessionInfo[] => state[AppSlice.name].sessionHistory;
export const useSessionHistory = (): ChatSessionInfo[] => useSelector(sessionHistorySelector);

const currencySelector = (state: IAppStates): string => state[AppSlice.name].activeCurrency;
export const useActiveCurrency = (): string => useSelector(currencySelector);

export const loadSessionDetails =
	(navigate: NavigateFunction, sessionId: string) =>
	async (dispatch: TDispatch, getStates: () => IAppStates): Promise<void> => {
		const savedSessionId = getStates()[AppSlice.name].chatSessionDetails?.sessionInfo?.id;
		if (savedSessionId === sessionId) return;
		navigate([PATH.HOME, PATH_VARIABLE.SEARCH, PATH_VARIABLE.CHAT].join('/'));
		await dispatch(GetSession([sessionId]));
	};

export const loadExchangeRates =
	() =>
	async (dispatch: TDispatch, getStates: () => IAppStates): Promise<void> => {
		const rates = getStates()[AppSlice.name].exchangeRate;
		if (Object.keys(rates).length) return;
		await dispatch(CurrencyExchangeRate([]));
	};

export const loadSessionHistory =
	() =>
	async (dispatch: TDispatch, getStates: () => IAppStates): Promise<void> => {
		const history = getStates()[AppSlice.name].sessionHistory;
		if (history.length) return;
		await dispatch(SessionHistory([]));
	};
