export enum STORAGE {
	TOKEN = 'TOKEN',
	REFRESH_TOKEN = 'REFRESH_TOKEN',
	LAT = 'LAT',
	LON = 'LON',
	CURRENCY = 'CURRENCY',
}

export enum PATH_VARIABLE {
	SEARCH = 'search',
	MAP = 'map',
	CHECKLIST = 'checklist',
	CHAT = 'chat',
}

export enum PATH {
	SIGN_IN = '/sign-in',
	HOME = '/explore',
	WELCOME = '/welcome',
}

export enum CURRENCY {
	INR = '₹',
}

export enum PLACEHOLDERS {
	RATING = 4.96,
	AMOUNT = 1000,
}
