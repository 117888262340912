/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * api-monolith API
 * OpenAPI spec version: 1.0-SNAPSHOT
 */
import type {
	ChatAudit,
	ChatSessionDetails,
	ChatSessionInfo,
	ChatUserInput,
	GetChatHistoryParams,
	GetSessionDetailsParams,
	GetSessionHistoryParams,
	SpotChecklist,
	SpotResponseObject,
	SpotType,
	UpdateSessionParams,
} from '.././models';
import { ApiRequest } from '../AuthRequest';

/**
 * Get Responses
 * @summary Get spot responses
 */
export const getResponses = (spotChecklist: SpotChecklist) => {
	return ApiRequest<SpotResponseObject>({
		url: `/api/chat/response`,
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		data: spotChecklist,
	});
};
/**
 * Get Sessions for a user
 */
export const getSessionHistory = (params?: GetSessionHistoryParams) => {
	return ApiRequest<ChatSessionInfo[]>({ url: `/api/chat/sessions`, method: 'GET', params });
};
/**
 * Get Session Details
 * @summary Get chat session
 */
export const getSessionDetails = (sessionId: string, params?: GetSessionDetailsParams) => {
	return ApiRequest<ChatSessionDetails>({ url: `/api/chat/sessions/${sessionId}`, method: 'GET', params });
};
/**
 * Update Session
 * @summary Update chat session
 */
export const updateSession = (sessionId: string, chatUserInput: ChatUserInput, params?: UpdateSessionParams) => {
	return ApiRequest<ChatSessionDetails>({
		url: `/api/chat/sessions/${sessionId}`,
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		data: chatUserInput,
		params,
	});
};
/**
 * Get Chat History
 * @summary Get chat history
 */
export const getChatHistory = (sessionId: string, params: GetChatHistoryParams) => {
	return ApiRequest<ChatAudit[]>({ url: `/api/chat/sessions/${sessionId}/history`, method: 'GET', params });
};
/**
 * Start New Chat
 * @summary Start a new chat session
 */
export const startNewChat = (type: SpotType, chatUserInput: ChatUserInput) => {
	return ApiRequest<ChatSessionDetails>({
		url: `/api/chat/${type}/new`,
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		data: chatUserInput,
	});
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type GetResponsesResult = NonNullable<Awaited<ReturnType<typeof getResponses>>>;
export type GetSessionHistoryResult = NonNullable<Awaited<ReturnType<typeof getSessionHistory>>>;
export type GetSessionDetailsResult = NonNullable<Awaited<ReturnType<typeof getSessionDetails>>>;
export type UpdateSessionResult = NonNullable<Awaited<ReturnType<typeof updateSession>>>;
export type GetChatHistoryResult = NonNullable<Awaited<ReturnType<typeof getChatHistory>>>;
export type StartNewChatResult = NonNullable<Awaited<ReturnType<typeof startNewChat>>>;
