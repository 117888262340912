import { ExternalApiRequest } from 'api/AuthRequest';
import { ExchangeRates } from 'api/models/exchangeCurrency';

export const currencyExchangeRate = () => {
	return ExternalApiRequest<ExchangeRates>({
		url: `https://open.er-api.com/v6/latest/USD`,
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	});
};
