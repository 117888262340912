import { ICurrency } from 'types';

/* eslint-disable no-useless-escape */
export const EMAIL_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MOBILE_REGEX = /^[0]?[6789]\d{9}$/;

export const SOMETHING_WENT_WRONG = 'Something went wrong!';

export const CURRENCIES: Record<string, ICurrency> = {
	USD: {
		name: 'US Dollar',
		symbol: '$',
		flag: '🇺🇸',
		decimal_digits: 2,
		country: 'United States of America (USA)',
	},
	EUR: {
		name: 'Euro',
		symbol: '€',
		flag: '🇪🇺',
		decimal_digits: 2,
		country: 'European Union',
	},
	GBP: {
		name: 'British Pound',
		symbol: '£',
		flag: '🇬🇧',
		decimal_digits: 2,
		country: 'United Kingdom',
	},
	JPY: {
		name: 'Japanese Yen',
		symbol: '¥',
		flag: '🇯🇵',
		decimal_digits: 0,
		country: 'Japan',
	},
	AUD: {
		name: 'Australian Dollar',
		symbol: 'A$',
		flag: '🇦🇺',
		decimal_digits: 2,
		country: 'Australia',
	},
	CAD: {
		name: 'Canadian Dollar',
		symbol: 'CA$',
		flag: '🇨🇦',
		decimal_digits: 2,
		country: 'Canada',
	},
	CHF: {
		name: 'Swiss Franc',
		symbol: 'CHF',
		flag: '🇨🇭',
		decimal_digits: 2,
		country: 'Switzerland',
	},
	CNY: {
		name: 'Chinese Yuan',
		symbol: '¥',
		flag: '🇨🇳',
		decimal_digits: 2,
		country: 'China',
	},
	INR: {
		name: 'Indian Rupee',
		symbol: '₹',
		flag: '🇮🇳',
		decimal_digits: 2,
		country: 'India',
	},
	SGD: {
		name: 'Singapore Dollar',
		symbol: 'S$',
		flag: '🇸🇬',
		decimal_digits: 2,
		country: 'Singapore',
	},
	NZD: {
		name: 'New Zealand Dollar',
		symbol: 'NZ$',
		flag: '🇳🇿',
		decimal_digits: 2,
		country: 'New Zealand',
	},
	HKD: {
		name: 'Hong Kong Dollar',
		symbol: 'HK$',
		flag: '🇭🇰',
		decimal_digits: 2,
		country: 'Hong Kong',
	},
	KRW: {
		name: 'South Korean Won',
		symbol: '₩',
		flag: '🇰🇷',
		decimal_digits: 0,
		country: 'South Korea',
	},
	MXN: {
		name: 'Mexican Peso',
		symbol: 'MX$',
		flag: '🇲🇽',
		decimal_digits: 2,
		country: 'Mexico',
	},
	AED: {
		name: 'UAE Dirham',
		symbol: 'AED',
		flag: '🇦🇪',
		decimal_digits: 2,
		country: 'United Arab Emirates',
	},
	THB: {
		name: 'Thai Baht',
		symbol: '฿',
		flag: '🇹🇭',
		decimal_digits: 2,
		country: 'Thailand',
	},
	SAR: {
		name: 'Saudi Riyal',
		symbol: 'SR',
		flag: '🇸🇦',
		decimal_digits: 2,
		country: 'Saudi Arabia',
	},
	BRL: {
		name: 'Brazilian Real',
		symbol: 'R$',
		flag: '🇧🇷',
		decimal_digits: 2,
		country: 'Brazil',
	},
	RUB: {
		name: 'Russian Ruble',
		symbol: '₽',
		flag: '🇷🇺',
		decimal_digits: 2,
		country: 'Russia',
	},
};

export const AMENITY_ICONS: Record<string, string> = {
	bathroom: '🚿',
	bedroom: '🛏️',
	kitchen: '🍳',
	'living area': '🛋️',
	outdoors: '🌳',
	'media & technology': '📺',
	'food & drink': '☕',
	services: '🛎️',
	'safety & security': '🔒',
	general: '✨',
	'languages spoken': '🗣️',
};
