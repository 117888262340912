import React from 'react';

import { PATH } from 'Enum';

// Lazy loaded components
const Home = React.lazy(() => import('pages/Home'));
const Authentication = React.lazy(() => import('pages/Authentication'));
const Welcome = React.lazy(() => import('pages/Welcome'));

interface IScreenConfig {
	sideBarVisible: boolean;
}

interface IRouteConfig {
	path: string;
	element: React.ReactNode;
	screen: IScreenConfig;
}

// Default screen configuration
export const DEFAULT_SCREEN_CONFIG: IScreenConfig = {
	sideBarVisible: false,
};

// Combined route and screen configuration
export const ROUTE_CONFIG = (): IRouteConfig[] => [
	{
		path: `${PATH.HOME}/:type?/:tab?`,
		element: <Home />,
		screen: {
			sideBarVisible: true,
		},
	},
	{
		path: PATH.SIGN_IN,
		element: <Authentication />,
		screen: DEFAULT_SCREEN_CONFIG,
	},
	{
		path: PATH.WELCOME,
		element: <Welcome />,
		screen: DEFAULT_SCREEN_CONFIG,
	},
];

// Helper function to get screen config for a given path
export const getScreenConfig = (pathname: string): IScreenConfig => {
	const route = ROUTE_CONFIG().find((route) => pathname.startsWith(route.path.split('/:')[0]));
	return route?.screen || DEFAULT_SCREEN_CONFIG;
};
