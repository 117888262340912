import React, { useEffect } from 'react';

import Cross from 'assets/cross.gif';
import CrossIcon from 'assets/CrossIcon';
import PartyPopper from 'assets/partypopper.gif';
import { IToast } from 'slices/ToastSlice';

import { useMediaQuery } from '../hooks/useMediaQuery';
import { DESKTOP_SCREEN } from '../theme';
import Box from './Box';
import Typography from './Typography';

const TOAST_TYPES = {
	SUCCESS: {
		icon: Cross,
		background: '#EBFBF1',
		color: '#16B364',
		borderColor: '#B6F4D3',
	},
	ERROR: {
		icon: Cross,
		background: '#FFEFEF',
		color: '#F04438',
		borderColor: '#FFD7D7',
	},
	WARNING: {
		icon: Cross,
		background: '#FFF9F5',
		color: '#FDB022',
		borderColor: '#FDDCAB',
	},
	CONGRATS: {
		icon: PartyPopper,
		background: '#F0F3FF',
		color: '#444CE7',
		borderColor: '#D6DDFF',
	},
};

const DEFAULT_DURATION = 3000; // 5 seconds

interface ToastProps extends IToast {
	onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, description, type, duration = DEFAULT_DURATION, onClose }) => {
	const isDesktop = useMediaQuery(DESKTOP_SCREEN);
	const toastConfig = TOAST_TYPES[type];

	useEffect(() => {
		const timer = setTimeout(() => {
			onClose();
		}, duration);

		return () => clearTimeout(timer);
	}, [duration, onClose]);

	return (
		<Box
			className="animated fadeInRight"
			display="flex"
			alignItems="flex-start"
			gap={12}
			padding={isDesktop ? '12px 16px' : '10px 12px'}
			borderRadius={12}
			background={toastConfig.background}
			border={`1px solid ${toastConfig.borderColor}`}
			width="100%"
			maxWidth={isDesktop ? 380 : 'calc(100% - 32px)'}
			position="relative">
			<img src={toastConfig.icon} width={isDesktop ? 20 : 16} />
			<Box display="flex" flexDirection="column" gap={4} flex={1}>
				<Typography fontSize={isDesktop ? 14 : 13} fontWeight={500} color="#344054" fontFamily="medium-font">
					{message}
				</Typography>
				{description && (
					<Typography fontSize={isDesktop ? 12 : 11} color="#667085">
						{description}
					</Typography>
				)}
			</Box>
			<Box
				position="absolute"
				right={isDesktop ? 12 : 8}
				top={isDesktop ? 12 : 8}
				cursor="pointer"
				onClick={onClose}>
				<CrossIcon width={isDesktop ? 16 : 14} htmlColor="#98A2B3" />
			</Box>
		</Box>
	);
};

export default React.memo(Toast);
